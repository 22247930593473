import Layout from "@/components/sites/Layout";
import Link from "next/link";
import { useRouter } from "next/router";

import Loader from "@/components/sites/Loader";
import Date from "@/components/Date";


import type { GetServerSidePropsContext, GetStaticPaths, GetStaticProps } from "next";
import type { Meta, SiteData, SiteInfo, SiteMenuItem } from "@/types";
import type { ParsedUrlQuery } from "querystring";


import { showConfettiAnimation } from '@/lib/showConfettiAnimation'

import { firebaseAdmin } from '@/lib/firebase/admin';
import { PageBlock, PageInfo } from "@/types/elements";

import ScrollReveal from "@/components/ScrollReveal";


const BioHeaderElement = dynamic(() => import("@/components/page-elements/BioHeader"), { ssr: false });
const FooterElement = dynamic(() => import("@/components/page-elements/FooterElement"), { ssr: false });
const H1Element = dynamic(() => import("@/components/page-elements/H1Element"), { ssr: false });
const Divider = dynamic(() => import("@/components/page-elements/Divider"), { ssr: false });
const NFTCollectionElement = dynamic(() => import("@/components/page-elements/NFTCollection"), { ssr: false });
const YoutubeChannelElement = dynamic(() => import("@/components/page-elements/YoutubeChannel"), { ssr: false });
const MusicLinkElement = dynamic(() => import("@/components/page-elements/MusicLink"), { ssr: false });
const TextElement = dynamic(() => import("@/components/page-elements/Text"), { ssr: false });
const ImageElement = dynamic(() => import("@/components/page-elements/Image"), { ssr: false });
const LinkCardElement = dynamic(() => import("@/components/page-elements/LinkCard"), { ssr: false });
const LinkButtonElement = dynamic(() => import("@/components/page-elements/LinkButton"), { ssr: false });
const YoutubeElement = dynamic(() => import("@/components/page-elements/YoutubeVideo"), { ssr: false });
const PDFElement = dynamic(() => import("@/components/page-elements/PDF"), { ssr: false });
const CollapsibleTextElement = dynamic(() => import("@/components/page-elements/CollapsibleText"), { ssr: false });
const TweetCardElement = dynamic(() => import("@/components/page-elements/TweetCard"), { ssr: false });
const CalendlyElement = dynamic(() => import("@/components/page-elements/Calendly"), { ssr: false });
const VimeoElement = dynamic(() => import("@/components/page-elements/VimeoVideo"), { ssr: false });
const SubstackPublicationElement = dynamic(() => import("@/components/page-elements/Substack"), { ssr: false });


import { useEffect } from "react";
import { Tracer } from "@/lib/tracer";
import dynamic from "next/dynamic";


interface PathProps extends ParsedUrlQuery {
  site: string;
}

interface IndexProps {
  data: any;
}




export default function Index({ data }: IndexProps) {
  const { site, page, menu } = JSON.parse(data) as SiteData;

  const router = useRouter();

  if (!site?.appearance) {
    site.appearance = {
      buttonTextColor: '#fff',
      buttonBackgroundColor: '#1f2937',
      corner: 'rounded',
      headerAlignment: 'center',
    }
  } else {
    if (!site.appearance.buttonTextColor) {
      site.appearance.buttonTextColor = '#fff';
    }
    if (!site.appearance.buttonBackgroundColor) {
      site.appearance.buttonBackgroundColor = '#1f2937';
    }
    if (!site.appearance.corner) {
      site.appearance.corner = 'rounded';
    }
    if (!site.appearance.headerAlignment) {
      site.appearance.headerAlignment = 'center';
    }
  }

  const meta = {
    title: site.name,
    description: site.description,
    logo: site.logo,
    ogImage: site.logo,
    ogUrl: site.domain
      ? site.subdomain
      : `https://${site.subdomain}.curious.page`,
  } as Meta;


  const tracer = new Tracer(site.id!, page.id!)

  useEffect(() => {
    ; (async function () {
      /* const ScrollReveal = (await import('scrollreveal')).default
      ScrollReveal().reveal('div:not(.ignore-reveal)')
      ScrollReveal().reveal('h1')
      ScrollReveal().reveal('h2')
      ScrollReveal().reveal('footer')
      ScrollReveal().reveal('header')
      ScrollReveal().reveal('button') */
    })()
    tracer.logPageView(site.domain)
    if (site.domain.includes("duns27")) {
        showConfettiAnimation();
    }
  }, [])

  if (router.isFallback) return <Loader />;

  const renderBlockElement = (block: PageBlock) => {
    if (block.type === 'text') {
      return <TextElement block={block} viewMode={true} appearance={site.appearance} tracer={tracer} />;
    }
    if (block.type === 'h1') {
      return <H1Element block={block} viewMode={true} appearance={site.appearance} tracer={tracer} />;
    }
    if (block.type === 'divider') {
      return <Divider block={block} viewMode={true} appearance={site.appearance} tracer={tracer} />;
    }
    if (block.type === 'button' || block.type === 'link-button') {
      return <LinkButtonElement block={block} viewMode={true} appearance={site.appearance} tracer={tracer} />;
    }
    if (block.type === 'image') {
      return <ImageElement block={block} viewMode={true} appearance={site.appearance} tracer={tracer} />;
    }
    if (block.type === 'youtube' || block.type === 'youtube-video') {
      return <YoutubeElement block={block} viewMode={true} appearance={site.appearance} tracer={tracer} />;
    }

    if (block.type === 'vimeo-video') {
      return <VimeoElement block={block} viewMode={true} appearance={site.appearance} tracer={tracer} />;
    }
    if (block.type === 'article' || block.type === 'link-card') {
      return <LinkCardElement block={block} viewMode={true} appearance={site.appearance} tracer={tracer} />;
    }
    if (block.type === 'pdf') {
      return <PDFElement block={block} viewMode={true} appearance={site.appearance} tracer={tracer} />;
    }
    if (block.type === 'accordion') {
      return <CollapsibleTextElement block={block} viewMode={true} appearance={site.appearance} tracer={tracer} />;
    }
    if (block.type === 'tweet') {
      return <TweetCardElement block={block} viewMode={true} appearance={site.appearance} tracer={tracer} />;
    }
    if (block.type === 'nft-collection') {
      return <NFTCollectionElement block={block} viewMode={true} appearance={site.appearance} tracer={tracer} />;
    }
    if (block.type === 'youtube-channel') {
      return <YoutubeChannelElement block={block} viewMode={true} appearance={site.appearance} tracer={tracer} />;
    }
    if (block.type === 'substack-publication') {
      return <SubstackPublicationElement block={block} viewMode={true} appearance={site.appearance} tracer={tracer} />;
    }
    if (block.type === 'music-link') {
      return <MusicLinkElement block={block} viewMode={true} appearance={site.appearance} tracer={tracer} />;
    }
    if (block.type === 'calendly') {
      return <CalendlyElement block={block} viewMode={true} appearance={site.appearance} tracer={tracer} />;
    }
  }

  return (
    <Layout site={site} subdomain={site.subdomain ?? undefined} menu={menu} slug={page.slug} appearance={site.appearance}>
      <div
        className="flex flex-col max-w-3xl min-h-screen p-1 mx-auto overflow-hidden bg-scroll md:pt-3 md:p-4 background ">
        <div className="relative max-w-3xl px-4 pb-10 md:px-8">
          <div className="flex flex-col items-center justify-center mx-auto space-y-4">

            {page.slug === 'home' && <BioHeaderElement site={site} viewMode={true} />}

            <div className="w-full h-[1px] bg-slate-200"></div>

            {page.slug !== 'home' && <p
              className="pt-10 text-2xl leading-tight text-gray-600 font-me md:text-4xl animated fadeInUp">{page.title}
            </p>}


            <div className="w-full" style={{ minHeight: '40vh' }}>
              <div className="space-y-4">
                {page['blocks'].map((value: PageBlock, index: number) => {
                  return <div
                    key={index}>{renderBlockElement(value)}</div>
                })}
              </div>
            </div>

            
            <FooterElement site={site} />
          </div>
        </div>
      </div>

      <style jsx global>
        {`
          :root {
            --btn-text-color: ${site.appearance.buttonTextColor};
            --btn-bg-color: ${site.appearance.buttonBackgroundColor};
          }
        `}
      </style>
    </Layout>
  );
}



const getSite = async (address: string, pageSlug: string = 'home') => {
  let siteDocQuerySnapshot = await firebaseAdmin.firestore().collection('Sites').where('domain', '==', address).get()
  if (siteDocQuerySnapshot.docs.length === 0) {
    siteDocQuerySnapshot = await firebaseAdmin.firestore().collection('Sites').where('subdomain', '==', address).get()
  }
  let sitePage: Record<string, any> = {};

  if (siteDocQuerySnapshot.docs.length > 0) {
    let siteId = siteDocQuerySnapshot.docs[0].id;
    let siteDoc = siteDocQuerySnapshot.docs[0].data();
    siteDoc['id'] = siteId;

    sitePage = { site: siteDoc, menu: [] };

    for (const pageId of siteDoc['pages']) {
      let pageDocRef = firebaseAdmin.firestore().collection('Pages').doc(pageId)
      let pageDoc = await pageDocRef.get()
      if (pageDoc.exists && pageDoc.data()) {
        let page: Record<string, any> = pageDoc.data() as Record<string, any>;
        page['id'] = pageDoc.id;
        if (page['slug'] === pageSlug /* && page['published'] */) { //TODO: fix publishing status
          sitePage['page'] = page;
        }
        if (true /* page['published'] */) { //TODO: fix publishing status
          sitePage['menu'].push({ name: page['title'], id: page.id, slug: page['slug'] })
        }
      }
    }
  }
  if (!sitePage['page']) return ''
  return JSON.stringify(sitePage);
}

export const getServerSideProps = async (ctx: GetServerSidePropsContext) => {
  try {
    if (!ctx.params) throw new Error("No path parameters found");

    const { address, page } = ctx.params;

    const filter = {
      customDomain: address?.includes(".") ? address : undefined,
      subdomain: address,
    };
    let data = await getSite(address as string, page as string);
    // let page: _PageData | undefined;
    if (data === '') return { notFound: true };

    ctx.res.setHeader(
      'Cache-Control',
      'public, s-maxage=10, stale-while-revalidate=59'
    )

    return {
      props: {
        revalidate: 3600,
        data,
      },
    }
  } catch (error) {
    console.error(error)
    return { notFound: true, revalidate: 10 };
  }
};